import React, { useState } from "react";
import {
  IconButton,
  makeStyles,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Clear } from "@material-ui/icons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import showToast from "../Toast";
import empresaService from "../../services/empresaService";
import TCButton from "../TCButton";
import authService from "../../services/authService";
import InviteToTradeCafeCopyLink from "../InviteToTradeCafeCopyLink";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    width: 350,
  },
  inpt: {
    marginBottom: 20,
    width: "100%",
  },
}));
const schema = yup.object().shape({
  email: yup.string().required("Preencha o email").email("Email invalido"),
  name: yup.string().required("Preencha o nome"),
});

// READ HERE

// This can be used to fire the invites to tradecafe aswell
export default function InviteUserToCompanyModal({
  handleClose,
  showModal,
  empresaId,
  nomeFantasia,
  onSuccess,
}) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: {}, resolver: yupResolver(schema) });
  function convidar(pData) {
    setLoading(true);
    let msg =
      "Convite enviado com sucesso! O convidado deve verificar seu email";

    if (!empresaId) {
      return authService
        .sendInviteEmail(pData)
        .then(() => {
          showToast.success(msg);
          setValue("email", "");
          setValue("name", "");
          handleClose();
        })
        .catch((e) => showToast.error(e.message))
        .finally(() => setLoading(false));
    }
    return empresaService
      .inviteToCompany(pData, empresaId)
      .then((rInvite) => {
        showToast.success(msg);
        setValue("email", "");
        setValue("name", "");
        onSuccess(rInvite);
        handleClose();
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => setLoading(false));
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        <form onSubmit={handleSubmit(convidar)}>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 id="transition-modal-title">
                Convidar para {nomeFantasia ? nomeFantasia : `TradeCafe`}{" "}
              </h2>
              <IconButton style={{ marginRight: -15 }} onClick={handleClose}>
                <Clear fontSize="medium" />
              </IconButton>
            </div>
            <Typography variant="subtitle1" style={{ marginBottom: 4 }}>
              Convidar por email
            </Typography>
            <TextField
              variant="outlined"
              className={classes.inpt}
              label={"Nome"}
              error={errors["name"]}
              helperText={errors["name"]?.message}
              {...register("name")}
              autoFocus
            />
            <TextField
              variant="outlined"
              className={classes.inpt}
              label={"Email"}
              error={errors["email"]}
              helperText={errors["email"]?.message}
              {...register("email")}
            />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div />
              <TCButton loading={loading} style={{ width: 110 }}>
                Convidar
              </TCButton>
            </div>
            {/* <div
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 4,
              }}
            >
              <Typography variant="subtitle1" style={{ marginBottom: 4 }}>
                Convidar por link
              </Typography>

              <InviteToTradeCafeCopyLink />
            </div> */}
          </div>
        </form>
      </Fade>
    </Modal>
  );
}
