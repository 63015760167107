import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import forumService from "@services/forumService";
import {
  makeStyles,
  Paper,
  TextareaAutosize,
  Divider,
  Grid,
} from "@material-ui/core";
import PostList from "./PostList";
import showToast from "../Toast";
import { grey } from "@material-ui/core/colors";
import { DARK } from "@utils/themes";
import { addPost, setPosts, setWaitingPosts } from "../../redux/forumReducer";
import TCButton from "../TCButton";

export const useStyles = (pTheme) =>
  makeStyles(() => ({
    textArea: {
      width: "100%",
      backgroundColor: pTheme === DARK ? grey[700] : "#f2f2f2",
      borderRadius: 4,
      fontSize: 12,
      color: pTheme === DARK ? "white" : grey[700],
      padding: 10,
      marginTop: 8,
      border: 0,
      resize: "none",
      mainContainer: {
        display: "flex",
      },
    },
  }));

function MainForum() {
  const [post, setPost] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, theme, posts, waitingPosts] = useSelector((states) => [
    states.authReducer.user,
    states.authReducer.theme,
    states.forumReducer.posts,
    states.forumReducer.waitingPosts,
  ]);

  const dispatch = useDispatch();
  const classes = useStyles(theme)();

  // useEffect(() => {
  //   if (!posts?.length) {
  //     getPosts();
  //   }
  // }, []);

  function getPosts(pQtdPosts) {
    setLoading(true);
    forumService
      .getPosts(pQtdPosts)
      .then((rPosts) => {
        if (pQtdPosts) {
          rPosts = [...posts, ...rPosts];
        }
        dispatch(setPosts(rPosts));
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }

  function handlePost() {
    if (!post || !title) {
      return;
    }
    forumService
      .post(title, post)
      .then((rNewPost) => {
        dispatch(addPost(rNewPost));
        setPost("");
        setTitle("");
      })
      .catch((e) => {
        console.log(e);
        showToast.error("Um erro ocorreu");
      });
  }

  function handleNewPosts() {
    dispatch(setPosts([...waitingPosts, ...posts]));
    dispatch(setWaitingPosts([]));
  }

  return (
    <Paper
      style={{
        width: "100%",
        minHeight: 800,
        padding: 20,
        marginBottom: 80,
      }}
    >
      {user?._id ? (
        <>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              marginBottom: 12,
            }}
          >
            <TextareaAutosize
              rows={1}
              placeholder="Titulo"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={classes.textArea}
              style={{ marginBottom: 8, fontSize: 12, color: "white" }}
              // maxlength="58"
            />
            <TextareaAutosize
              rows={4}
              placeholder="Mensagem"
              value={post}
              onChange={(e) => setPost(e.target.value)}
              className={classes.textArea}
            />
            <div style={{ alignSelf: "flex-end", marginTop: 10 }}>
              <TCButton
                color="primary"
                variant="contained"
                disabled={!post || !title}
                onClick={handlePost}
              >
                Postar
              </TCButton>
            </div>
          </div>
          <Divider />
        </>
      ) : (
        <></>
      )}

      <NewPosts
        waitingPosts={waitingPosts}
        theme={theme}
        handleNewPosts={handleNewPosts}
      />
      <PostList
        posts={posts}
        theme={theme}
        getPosts={getPosts}
        loading={loading}
      />
    </Paper>
  );
}

export default MainForum;

const useStyles2 = (pTheme, waitingPosts) =>
  makeStyles(() => ({
    newPosts: {
      height: waitingPosts?.length ? 50 : 0,
      width: "100%",
      transition: "0.2s",
      backgroundColor: pTheme === DARK ? grey[700] : grey[300],
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: pTheme === DARK ? grey[600] : grey[200],
        transition: "0.2s",
      },
    },
  }));
function NewPosts({ waitingPosts, theme, handleNewPosts }) {
  const classes = useStyles2(theme, waitingPosts)();
  return (
    <Grid className={classes.newPosts} onClick={handleNewPosts}>
      {waitingPosts.length ? `${waitingPosts.length} Nova Publicação` : ""}
    </Grid>
  );
}
